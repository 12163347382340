import * as _Types from './types';
import * as _Components from './components';
import * as _Hooks from './hooks';
import * as _Utils from './utils';
import * as _Effects from './effects';
import './styles/global.scss';

namespace PMIWeb {
  export const Components = _Components;
  export const Types = _Types;
  export const Hooks = _Hooks;
  export const Utils = _Utils;
  export const Effects = _Effects;
}

export default PMIWeb;
