import PMIWeb from '@pmi.web/ui';
import { useTranslation } from 'react-i18next';

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="text-center flex flex-col items-center">
      <h1 className="text-5xl text-error">404</h1>
      <p>Page not found</p>

      <br />

      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          PMIWeb.Utils.openWindow(
            'pmebusiness',
            process.env.REACT_APP_LINK_WEBSHOP ?? ''
          );
        }}
      >
        {t('Go to shop')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
