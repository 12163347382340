import { PageLayout } from './components/layout/PageLayout';
import { AuthCallbackPage } from './components/pages/auth-callback/AuthCallbackPage';
import { OnboardedPage } from './components/pages/set-password/OnboardedPage';
import { RegistrationCompletePage } from './components/pages/registration/RegistrationCompletePage';
import { RegistrationStartPage } from './components/pages/registration/RegistrationStartPage';

import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { RegistrationResumePage } from 'components/pages/registration/RegistrationResumePage';
import { EmailConfirmationPage } from 'components/pages/email-confirmation/EmailConfirmationPage';
import { SetPasswordPage } from 'components/pages/set-password/SetPasswordPage';
import { NotFoundPage } from 'components/pages/404/NotFoundPage';
import { RouteError } from 'components/common/ErrorHandler/RouteError';
import { I18nextProvider } from 'react-i18next';
import { i18nInstance } from 'assets/i18n';

const routesConf = [
  {
    path: '/',
    element: (
      <PageLayout>
        <Outlet />
      </PageLayout>
    ),
    errorElement: (
      <PageLayout>
        <RouteError />
      </PageLayout>
    ),
    children: [
      {
        index: true,
        element: <RegistrationStartPage />
      },
      {
        path: 'account/resume',
        element: <RegistrationResumePage />
      },
      {
        path: 'account/finish/:accessToken',
        element: <SetPasswordPage />
      },
      {
        path: 'authcallback',
        element: <AuthCallbackPage />
      },
      {
        path: 'onboarded',
        element: <OnboardedPage />
      },
      {
        path: 'registrationComplete',
        element: <RegistrationCompletePage />
      },
      {
        path: 'emailConfirmation',
        element: <EmailConfirmationPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export function AppRouter() {
  const router = createBrowserRouter(routesConf);

  return (
    <I18nextProvider i18n={i18nInstance}>
      <RouterProvider router={router}></RouterProvider>;
    </I18nextProvider>
  );
}
