import { ReactNode } from 'react';
import './Disclaimer.scss';

interface IDisclaimerProps {
  readonly children: ReactNode;
  readonly type: 'error' | 'warning' | 'info' | 'success';
}

export function Disclaimer(props: IDisclaimerProps) {
  return (
    <div className="pmi_web__Disclaimer" data-pmiweb-type={props.type}>
      {props.children}
    </div>
  );
}
