import { useState } from 'react';
import * as userManagement from '../../services/UserManagementService';
import { CustomerData } from '../../types/models';
import AuthenticationService from '../../services/AuthService';
import PMIWeb from '@pmi.web/ui';
import { CustomerRegistrationForm } from '@pmi.web/react-user-forms';
import { useTranslation } from 'react-i18next';
import { ICustomerRegistrationProps } from './ICustomerRegistrationProps';

export function USCustomerRegistration(props: ICustomerRegistrationProps) {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [submittingCustomer, setSubmittingCustomer] = useState<boolean>(false);

  const submit = async (customer: CustomerData) => {
    setSubmittingCustomer(true);

    const customerData: CustomerData = {
      ...customer,
      locale: language
    };

    try {
      const resp = await userManagement.registerCustomer(
        props.sponsorId,
        customerData
      );

      if (resp.ok) {
        const result = await resp.json();
        if (!result.otpNonce) {
          throw Error('Did not receive an OTP from User Management service');
        }

        await AuthenticationService.startupWithToken(
          result.otpNonce,
          result.id
        );

        const state = JSON.stringify({
          redirectUrl: props.redirectUrl,
          locale: language
        });

        await AuthenticationService.startSignIn(state);

        return;
      }
    } finally {
      setSubmittingCustomer(false);
    }
  };

  if (submittingCustomer) {
    return (
      <div className="min-h-[150px] flex items-center justify-center">
        <PMIWeb.Components.LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-sm">
      <h1>{t('Customer Registration')}</h1>

      <CustomerRegistrationForm
        country={props.country}
        language={language}
        onSubmit={submit}
        formId={'us-customer-form'}
      />

      <PMIWeb.Components.PrimaryButton type="submit" form="us-customer-form">
        {t('Submit')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
