import { InputHTMLAttributes } from 'react';
import './LabelledInput.scss';

interface ILabelledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly label?: string;
  readonly style?: any;
}

export function LabelledInput(props: ILabelledInputProps) {
  return (
    <fieldset className="pmi_web__LabelledInput">
      <input {...props} id={props.id} />
      {props.label && (
        <label htmlFor={props.id}>
          <small>{props.label}</small>
        </label>
      )}
    </fieldset>
  );
}
