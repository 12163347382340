import { ReactNode, useEffect } from 'react';
import { Header } from './Header';
import { ContentWrapper } from './ContentWrapper';
import { Footer } from './Footer';
import { Theme, useTheme } from '@pmi.web/react-theme';

interface IPageLayoutProps {
  readonly children: ReactNode;
}

const PM_FAVICON_URI = '/favicon-pm.ico';
const FITLINE_FAVICON_URI = '/favicon-fitline.ico';

export function PageLayout(props: IPageLayoutProps) {
  const { theme } = useTheme();

  useEffect(() => {
    updateHtmlThemeFaviconLink(theme);
  }, [theme]);

  return (
    <section className="fixed inset-0 flex flex-col overflow-x-hidden overflow-y-auto bg-background">
      <Header />
      <section className="relative z-10 flex-1 m-md">
        <ContentWrapper>{props.children}</ContentWrapper>
      </section>
      <Footer />
    </section>
  );
}

function updateHtmlThemeFaviconLink(theme: Theme) {
  const htmlFaviconLink = Array.from(
    document.getElementsByTagName('link')
  ).find(linkElement => linkElement.getAttribute('rel') === 'icon');

  const faviconPath =
    Theme.PM === theme
      ? PM_FAVICON_URI
      : Theme.FitLine === theme
        ? FITLINE_FAVICON_URI
        : undefined;

  if (htmlFaviconLink && faviconPath) {
    htmlFaviconLink.setAttribute('href', faviconPath);
  }
}
