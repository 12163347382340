import PMIWeb from '@pmi.web/ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function RegistrationCompletePage() {
  const { t } = useTranslation();

  useEffect(() => {
    PMIWeb.Effects.fireConfettiGun('red');
  }, []);

  return (
    <div className="flex flex-col items-center gap-md">
      <h1>{t('Congratulations!')}</h1>
      <p>{t('Your registration is completed.')}</p>

      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          PMIWeb.Utils.openWindow(
            'pmebusiness',
            process.env.REACT_APP_LINK_WEBSHOP ?? ''
          );
        }}
      >
        {t('Go to shop')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
