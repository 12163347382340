import ProspectRegistration from 'components/pages/registration/ProspectRegistration';
import {
  useCountry,
  useProspectId,
  useRedirectUrl,
  useSponsorId
} from 'hooks/useCustomSearchParams';
import { useProspect } from 'hooks/useProspect';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { SomethingWentWrong } from 'components/common/ErrorHandler/SomethingWentWrong';
import { useTranslation } from 'react-i18next';
import { CustomerRegistration } from 'components/customer-registration';

export function RegistrationStartPage() {
  const prospectId = useProspectId();
  const sponsorId = useSponsorId();

  if (prospectId) {
    return <ProspectRegistrationContent prospectId={prospectId} />;
  }

  if (sponsorId) {
    return <CustomerRegistrationContent sponsorId={sponsorId} />;
  }

  return <Navigate to={'/404'} replace />;
}

function CustomerRegistrationContent({ sponsorId }: { sponsorId: string }) {
  const redirectUrl = useRedirectUrl();
  const country = useCountry();

  if (!country) {
    return <Navigate to={'/404'} replace />;
  }

  return (
    <CustomerRegistration
      country={country}
      sponsorId={sponsorId}
      redirectUrl={redirectUrl}
    />
  );
}

function ProspectRegistrationContent({ prospectId }: { prospectId: string }) {
  const navigate = useNavigate();
  const redirectUrl = useRedirectUrl();
  const {
    i18n: { language }
  } = useTranslation();
  const { isSuccess, error } = useProspect(prospectId);

  useEffect(() => {
    if (!!error && redirectUrl) {
      window.location.assign(redirectUrl);
    }
  }, [error, redirectUrl]);

  if (!!error && !redirectUrl) {
    return <Navigate to={'/404'} replace />;
  }

  if (!isSuccess) {
    <div className="w-full flex justify-center">
      <SomethingWentWrong />
    </div>;
  }

  return (
    <ProspectRegistration
      prospectId={prospectId}
      redirectUrl={redirectUrl}
      onNeedsContinueToken={emailHint => {
        navigate(
          `/emailConfirmation?lang=${language}&hint=${emailHint}&prospectId=${prospectId}&redirectUrl=${redirectUrl}`
        );
      }}
    />
  );
}
