import { ReactNode } from 'react';
import Countdown from 'react-countdown';
import type { CountdownRenderProps } from 'react-countdown';

import './BannerCountdown.scss';
import { padWithZeros } from '../../utils';

interface IBannerCountdownProps {
  readonly to: Date;
  readonly renderer?: (props: CountdownRenderProps) => ReactNode;
}

export function BannerCountdown(props: IBannerCountdownProps) {
  const renderer = props.renderer ?? CountdownDefaultRenderer;

  return (
    <h1 className="pmiweb--banner-countdown">
      <Countdown date={props.to.getTime()} renderer={renderer} />
    </h1>
  );
}

function CountdownDefaultRenderer(props: CountdownRenderProps) {
  const { days, hours, minutes, seconds } = props;
  const paddedHours = padWithZeros(hours + convertDaysToHours(days), 2);
  const paddedMinutes = padWithZeros(minutes, 2);
  const paddedSeconds = padWithZeros(seconds, 2);

  return (
    <>
      {paddedHours}:{paddedMinutes}:{paddedSeconds}
    </>
  );
}

const HOURS_IN_ONE_DAY = 24;

function convertDaysToHours(days: number): number {
  return days * HOURS_IN_ONE_DAY;
}
