const SINGLE_ZERO_STRING = '0';

export function padWithZeros(
  value: number | string | undefined,
  minSize: number,
  side: 'left' | 'right' = 'left'
): string {
  let valueToPad: string;

  if (value === undefined) {
    valueToPad = '';
  } else if (typeof value === 'number') {
    valueToPad = value.toString();
  } else {
    valueToPad = value;
  }

  if (side === 'left') {
    return valueToPad.padStart(minSize, SINGLE_ZERO_STRING);
  }

  return valueToPad.padEnd(minSize, SINGLE_ZERO_STRING);
}
