import { useEffect, useState } from 'react';
import * as userManagement from '../../../services/UserManagementService';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  containsDigit,
  containsSpecialCharacter,
  containsLowerCaseCharacter
} from './PasswordsHelper';
import PMIWeb from '@pmi.web/ui';
import { FaCheck } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

export function SetPasswordPage() {
  const { accessToken } = useParams();

  const [
    isValidating,
    { setTrue: startValidation, setFalse: finishValidation }
  ] = PMIWeb.Hooks.useBoolean(true);
  const [
    isAccessTokenValid,
    { setTrue: approveAccessToken, setFalse: denyAccessToken }
  ] = PMIWeb.Hooks.useBoolean(false);

  useEffect(() => {
    const checkAccessTokenStillValid = async () => {
      const resp = await userManagement.validateSetCredentialsToken(
        accessToken ?? ''
      );

      if (resp.ok) {
        // access token is still good, we can continue on this page
        approveAccessToken();
      } else {
        // access token is invalid, redirect to shop
        denyAccessToken();
      }
    };

    startValidation();
    checkAccessTokenStillValid().finally(() => finishValidation());
  }, [
    accessToken,
    approveAccessToken,
    denyAccessToken,
    finishValidation,
    startValidation
  ]);

  useEffect(() => {
    if (
      isValidating === false &&
      isAccessTokenValid === false &&
      process.env.REACT_APP_LINK_WEBSHOP
    ) {
      window.location.assign(process.env.REACT_APP_LINK_WEBSHOP);
    }
  }, [isValidating, isAccessTokenValid]);

  if (
    isValidating === false &&
    isAccessTokenValid === false &&
    !process.env.REACT_APP_LINK_WEBSHOP
  ) {
    return <Navigate to={'/404'} replace />;
  }

  if (isValidating === false && isAccessTokenValid === true) {
    return <SetPasswordPageContent />;
  }

  return (
    <div className="min-h-[150px] flex items-center justify-center">
      <PMIWeb.Components.LoadingSpinner />
    </div>
  );
}

function SetPasswordPageContent() {
  const { accessToken } = useParams();
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [loading, { setFalse: stopLoading, setTrue: startLoading }] =
    PMIWeb.Hooks.useBoolean(false);
  const [errorFromServer, setErrorFromServer] = useState<string | undefined>();

  const validations = [];

  const passLength: boolean = !!password && password.length >= 8;
  validations.push({
    message: t('At least 8 characters'),
    fulfilled: passLength
  });

  const hasLowerCaseLetter: boolean =
    !!password && containsLowerCaseCharacter(password);
  validations.push({
    message: t('At least 1 lowercase letter'),
    fulfilled: hasLowerCaseLetter
  });

  const hasDigit: boolean = !!password && containsDigit(password);
  validations.push({
    message: t('At least 1 number'),
    fulfilled: hasDigit
  });

  const containsSpecialChar: boolean =
    !!password && containsSpecialCharacter(password);
  validations.push({
    message: t('At least 1 special character'),
    fulfilled: containsSpecialChar
  });

  const passMatch: boolean = !!password && password === passwordConfirmation;
  validations.push({
    message: t('Passwords must match'),
    fulfilled: passMatch
  });

  const canSubmit = validations.every(x => x.fulfilled);

  const reset = () => {
    setPassword('');
    setPasswordConfirmation('');
  };

  const onSubmit = async () => {
    if (!canSubmit) {
      return;
    }

    startLoading();

    try {
      const resp = await userManagement.setCredentials({
        accessToken: accessToken ?? '',
        password,
        passwordConfirmation
      });

      if (resp.ok) {
        navigate(`/onboarded?lang=${language}`);
      } else {
        try {
          const respDeserialized = await resp.json();
          if (respDeserialized.detail) {
            setErrorFromServer(respDeserialized.detail);
            return;
          }
        } catch (_) {
          // we couldn't deserialize
        }
        setErrorFromServer(t('Error when setting password ') + resp.statusText);
      }
    } finally {
      stopLoading();
      reset();
    }
  };

  return (
    <div className="space-y-md">
      <h1>{t('Set your Password')}</h1>
      {errorFromServer && (
        <PMIWeb.Components.Disclaimer type="error">
          <p>
            <small>
              <FaTriangleExclamation size={16} className="inline-block mr-xs" />
              {errorFromServer}
            </small>
          </p>
        </PMIWeb.Components.Disclaimer>
      )}

      {loading && (
        <div className="min-h-[150px] flex items-center justify-center">
          <PMIWeb.Components.LoadingSpinner />
        </div>
      )}

      {!loading && (
        <>
          <PMIWeb.Components.LabelledInput
            label={t('Password')}
            id="password"
            aria-label={t('Password')}
            type="password"
            required
            onChange={event => {
              setPassword(event.target.value);
            }}
          />

          <PMIWeb.Components.LabelledInput
            label={t('Password Confirmation')}
            id="password-confirmation"
            aria-label={t('Password Confirmation')}
            type="password"
            required
            onChange={event => {
              setPasswordConfirmation(event.target.value);
            }}
          />

          <ul className="space-y-xs">
            {validations.map((x, i) => {
              return (
                <li key={i} className="flex items-center gap-xs">
                  <span
                    className={`rounded-full h-[16px] w-[16px] flex items-center justify-center ${
                      x.fulfilled ? 'text-white bg-primary' : 'bg-secondary'
                    }`}
                  >
                    {x.fulfilled && <FaCheck size={10} />}
                  </span>
                  <p>{x.message}</p>
                </li>
              );
            })}
          </ul>

          <PMIWeb.Components.PrimaryButton
            disabled={!canSubmit}
            onClick={onSubmit}
          >
            {t('Set password')}
          </PMIWeb.Components.PrimaryButton>
        </>
      )}
    </div>
  );
}
