import { ISOCountryCode, parseCountryCode } from '@pmi.web/countries';
import { ICustomerRegistrationProps } from './ICustomerRegistrationProps';
import { USCustomerRegistration } from './USCustomerRegistration';
import { GenericCustomerRegistration } from './GenericCustomerRegistration';

export function CustomerRegistration(props: ICustomerRegistrationProps) {
  if (parseCountryCode(props.country) === ISOCountryCode.UnitedStatesAmerica) {
    return <USCustomerRegistration {...props} />;
  }

  return <GenericCustomerRegistration {...props} />;
}
