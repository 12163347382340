import PMIWeb from '@pmi.web/ui';
import { useCallback } from 'react';
import { getProspect } from 'services/UserManagementService';
import { ProspectData } from 'types/models';

export function useProspect(prospectId?: string, continueToken?: string) {
  const fetchFn = useCallback(async () => {
    if (!prospectId) {
      return Promise.reject();
    }

    const response = await getProspect(prospectId, continueToken);

    if (!response.ok) {
      throw response;
    }

    let jsonData: any = undefined;
    try {
      jsonData = await response.json();
    } catch (e) {
      console.error(e);
      throw new Error('Could not retrieve prospect.');
    }
    return jsonData as ProspectData;
  }, [prospectId, continueToken]);

  return PMIWeb.Hooks.useAsyncFetcher(fetchFn);
}
