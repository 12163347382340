import { ProspectData, CredentialsData, CustomerData } from '../types/models';

export const getProspect = async (
  prospectId: string,
  continueToken?: string
) => {
  return fetch(
    continueToken && continueToken.length > 0
      ? `${process.env.REACT_APP_USERMANAGEMENT_API}/users/prospects/customer/${prospectId}?continuation=${continueToken}`
      : `${process.env.REACT_APP_USERMANAGEMENT_API}/users/prospects/customer/${prospectId}`
  );
};

export const onboardProspect = async (
  prospect: ProspectData,
  continueToken?: string
) => {
  if (!prospect?.address?.country) {
    throw new Error('Cannot save Prospect. There is no Address.Country field');
  }

  return fetch(
    continueToken && continueToken.length > 0
      ? `${
          process.env.REACT_APP_USERMANAGEMENT_API
        }/prospects/customer/onboard/${prospect.address.country.toLowerCase()}?continuation=${continueToken}`
      : `${
          process.env.REACT_APP_USERMANAGEMENT_API
        }/prospects/customer/onboard/${prospect.address.country.toLowerCase()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(prospect)
    }
  );
};

export const registerCustomer = async (
  sponsorId: string,
  customer: CustomerData
) => {
  if (!customer?.address?.country) {
    throw new Error('Cannot save Customer. There is no Country field');
  }

  return fetch(
    `${
      process.env.REACT_APP_USERMANAGEMENT_API
    }/signup/${customer.address.country.toUpperCase()}?sponsorId=${sponsorId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customer)
    }
  );
};

export const confirmEmail = async (
  prospectId: string,
  email: string,
  redirectUrl?: string
) => {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/users/prospects/customer/resume`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ prospectId, email, redirectUrl })
    }
  );
};

/// Check if SetCredentials access token is still valid
export const validateSetCredentialsToken = async (accessToken: string) => {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/users/credentials/preflight/${accessToken}`
  );
};

export const setCredentials = async (credentials: CredentialsData) => {
  return fetch(
    `${process.env.REACT_APP_USERMANAGEMENT_API}/users/credentials`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }
  );
};
