import { ButtonHTMLAttributes, LegacyRef, ReactNode, forwardRef } from 'react';
import './PrimaryButton.scss';

interface IPrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly children: ReactNode;
  readonly style?: any;
}

function PrimaryButtonComponent(
  props: IPrimaryButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  const { children, ...buttonProps } = props;
  return (
    <button {...buttonProps} className="pmi_web__PrimaryButton" ref={ref}>
      {children}
    </button>
  );
}

export const PrimaryButton = forwardRef(PrimaryButtonComponent);
