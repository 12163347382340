import { ReactNode } from 'react';
import type { CountdownRenderProps } from 'react-countdown';

import './Banner.scss';
import { BannerCountdown } from './BannerCountdown';
import { BannerCta } from './BannerCta';

export interface IBannerProps {
  readonly title?: string;
  readonly description?: string;
  readonly countdownTo?: Date;
  readonly countdownRenderer?: (props: CountdownRenderProps) => ReactNode;
}

interface IActionableBannerProps extends IBannerProps {
  readonly ctaUrl: string;
  readonly ctaLabel: string;
  readonly onCta?: () => void;
}

export function Banner(props: IBannerProps | IActionableBannerProps) {
  function isActionableBanner(
    banner: IBannerProps | IActionableBannerProps
  ): banner is IActionableBannerProps {
    const hasActionableProperties = 'ctaUrl' in banner && 'ctaLabel' in banner;

    if (hasActionableProperties) {
      // This is done to prevent showing a cta button that will navigate
      // the user to the exact same page as the one he is now.
      const hasCtaUrlDifferentThanCurrentRoute =
        window.location.href.replace(window.location.search, '') !==
        banner.ctaUrl;

      return hasCtaUrlDifferentThanCurrentRoute;
    }

    return false;
  }

  return (
    <div className="pmiweb--banner">
      {props.title && <h1>{props.title}</h1>}
      {props.description && <p>{props.description}</p>}
      <div className="pmiweb--banner-ctawrapper">
        {props.countdownTo ? (
          <BannerCountdown
            to={props.countdownTo}
            renderer={props.countdownRenderer}
          />
        ) : (
          <span></span>
        )}
        {isActionableBanner(props) && (
          <BannerCta
            label={props.ctaLabel}
            url={props.ctaUrl}
            onCta={props.onCta}
          />
        )}
      </div>
    </div>
  );
}
