import {
  parseSupportedLanguage,
  SupportedLanguage
} from '@pmi.web/localization';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useProspectId() {
  const [searchParams] = useSearchParams();
  const prospectId =
    searchParams.get('prospectId') ?? searchParams.get('p') ?? undefined;

  return prospectId;
}

export function useSponsorId() {
  const [searchParams] = useSearchParams();
  const sponsorId = searchParams.get('sponsorId') ?? undefined;

  return sponsorId;
}

export function useRedirectUrl() {
  const [searchParams] = useSearchParams();
  const redirectUrlParam = searchParams.get('redirectUrl') ?? undefined;

  if (redirectUrlParam) {
    try {
      return decodeURIComponent(redirectUrlParam);
    } catch (err) {
      return redirectUrlParam;
    }
  }

  return undefined;
}

export function useContinueToken() {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('t') ?? undefined;

  return redirectUrl;
}

// TODO: refactor this. We should not show blindly what is written in the URL!
export function useEmailHint() {
  const [searchParams] = useSearchParams();
  const hint = searchParams.get('hint') ?? undefined;

  return hint;
}

export function useLang() {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lang') ?? '';
  const activeLanguage = parseSupportedLanguage(lang ?? '');

  const setActiveLanguage = useCallback(
    (language: string) => {
      searchParams.set('lang', language);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return { activeLanguage, setActiveLanguage };
}

export function useCountry() {
  const [searchParams] = useSearchParams();
  const country = searchParams.get('country') ?? undefined;

  return country;
}
