import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/tailwind.scss';
import { AppRouter } from 'AppRouter';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import PMIWeb from '@pmi.web/ui';
import { ErrorHandler } from 'components/common/ErrorHandler/ErrorHandler';
import { initClarity } from 'services/Clarity';
import { Theme, ThemeManager } from '@pmi.web/react-theme';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'libs/monitoring';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initClarity();

function logError(error: Error, info: ErrorInfo): void {
  console.error('[ErrorBoundary]', error, info);
}

function fallbackRender(props: FallbackProps) {
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center gap-md">
      <ErrorHandler error={props.error} />
      <PMIWeb.Components.PrimaryButton onClick={props.resetErrorBoundary}>
        Retry
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}

const theme = window?.location.host.includes('.pm-international.')
  ? Theme.PM
  : Theme.FitLine;

root.render(
  <React.StrictMode>
    <ThemeManager defaultTheme={theme} />
    <ErrorBoundary onError={logError} fallbackRender={fallbackRender}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppRouter />
      </AppInsightsContext.Provider>
    </ErrorBoundary>
  </React.StrictMode>
);
