import PMIWeb from '@pmi.web/ui';
import { useEffect } from 'react';
import { isRouteErrorResponse } from 'react-router-dom';

function isError(error: any): error is Error {
  return 'message' in error;
}

export function ErrorHandler(props: { error?: unknown }) {
  useEffect(() => {
    if (props.error) {
      console.error(props.error);
    }
  }, [props.error]);

  return (
    <div className="text-center space-y-md">
      <object
        title="Something went wrong Illustration"
        className="inline-block h-[120px]"
        type="image/svg+xml"
        data="/imgs/errors/SomethingWentWrongError.svg"
      ></object>
      <h1 className="text-5xl text-error">Oops!</h1>

      <PMIWeb.Components.Disclaimer type="error">
        {/* If it's an ErrorResponse ... */}
        {!!props.error && isRouteErrorResponse(props.error) && (
          <>
            <h2>{props.error.status}</h2>
            <p>{props.error.statusText}</p>
            {props.error.data?.message && <p>{props.error.data.message}</p>}
          </>
        )}

        {/* If it's an Error ... */}
        {!!props.error && isError(props.error) && <p>{props.error.message}</p>}

        {/* If no error ... */}
        {!props.error && <p>Something went wrong</p>}

        {/* Else ... */}
        {!!props.error &&
          !isError(props.error) &&
          !isRouteErrorResponse(props.error) && (
            <p>{JSON.stringify(props.error)}</p>
          )}
      </PMIWeb.Components.Disclaimer>
    </div>
  );
}
