import { useEffect } from 'react';
import PMIWeb from '@pmi.web/ui';
import { Theme, useTheme } from '@pmi.web/react-theme';
import { useTranslation } from 'react-i18next';

export function OnboardedPage() {
  const { t } = useTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    const confettiColor = theme === Theme.FitLine ? 'red' : 'blue';
    PMIWeb.Effects.fireConfettiGun(confettiColor);
  }, [theme]);

  return (
    <div className="space-y-sm flex flex-col items-center">
      <h1>{t('Thank you')}</h1>
      <p>{t('Your new password has been saved')}</p>
      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          PMIWeb.Utils.openWindow(
            'pmebusiness',
            process.env.REACT_APP_LINK_WEBSHOP ?? ''
          );
        }}
      >
        {t('Go to shop')}
      </PMIWeb.Components.PrimaryButton>
    </div>
  );
}
