import PMIWeb from '@pmi.web/ui';
import { ChangeEvent, useState } from 'react';
import * as userManagement from '../../../services/UserManagementService';
import {
  useEmailHint,
  useProspectId,
  useRedirectUrl
} from 'hooks/useCustomSearchParams';
import { EmailConfirmedSuccessfully } from './EmailConfirmedSuccessfully';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EMAIL_REGEX = /.+@.+\..+/;

export function EmailConfirmationPage() {
  const prospectId = useProspectId();

  if (prospectId === undefined) {
    return <Navigate to={'/404'} replace />;
  }

  return <EmailConfirmationPageContent />;
}

function EmailConfirmationPageContent() {
  const { t } = useTranslation();
  const prospectId = useProspectId() ?? '';
  const emailHint = useEmailHint() ?? '';
  const redirectUrl = useRedirectUrl() ?? '';

  const [email, setEmail] = useState<string>();
  const [isSubmitting, { setTrue: startSubmission, setFalse: stopSubmission }] =
    PMIWeb.Hooks.useBoolean(false);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const onEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue && inputValue.length > 0 && EMAIL_REGEX.test(inputValue)) {
      setEmail(inputValue);
    } else {
      setEmail(undefined);
    }
  };

  const onSubmit = async () => {
    startSubmission();

    if (!email) {
      return;
    }

    try {
      const resp = await userManagement.confirmEmail(
        prospectId,
        email,
        redirectUrl
      );
      if (!resp.ok) {
        // TODO: handle error case
        return;
      }

      setShowSuccess(true);
    } finally {
      stopSubmission();
    }
  };

  if (showSuccess) {
    return <EmailConfirmedSuccessfully />;
  }

  return (
    <div className="space-y-sm">
      <h1>{t('Confirm your email address')}</h1>

      {isSubmitting && (
        <div className="min-h-[150px] flex items-center justify-center">
          <PMIWeb.Components.LoadingSpinner />
        </div>
      )}

      {!isSubmitting && (
        <>
          <p>{t('Please enter the email address used during registration.')}</p>
          {emailHint && emailHint.length > 0 && (
            <p>{t("Here's a hint: {{hint}}", { hint: emailHint })}</p>
          )}

          <PMIWeb.Components.LabelledInput
            label={t('Email address')}
            id="email"
            aria-label={t('Email address')}
            type="email"
            required
            onChange={onEmailInputChange}
          />

          <PMIWeb.Components.PrimaryButton disabled={!email} onClick={onSubmit}>
            {t('Submit')}
          </PMIWeb.Components.PrimaryButton>
        </>
      )}
    </div>
  );
}
