import {
  parseSupportedLanguage,
  SupportedLanguage
} from '@pmi.web/localization';
import { initReactI18next } from 'react-i18next';

import en from './en-US.json';
import { use } from 'i18next';

const DEFAULT_NS = 'pmi.web.registration';

export const i18nInstance = use(initReactI18next).createInstance({
  fallbackLng: SupportedLanguage.English,
  defaultNS: DEFAULT_NS,
  ns: DEFAULT_NS,
  resources: {
    [SupportedLanguage.English]: { [DEFAULT_NS]: en }
  },
  keySeparator: false,
  nsSeparator: false,
  /**
   * react-i18next options
   */
  react: {
    bindI18n: 'languageChanged',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['h1', 'h2', 'p', 'ul', 'li', 'strong']
  }
});

export async function setSelectedLanguage(language: string) {
  const supportedLanguage = parseSupportedLanguage(language);
  await addTranslationsFor(supportedLanguage);
  return i18nInstance.changeLanguage(supportedLanguage);
}

async function addTranslationsFor(language: SupportedLanguage): Promise<void> {
  if (i18nInstance.hasResourceBundle(language, DEFAULT_NS)) {
    return Promise.resolve();
  }

  const translations = await loadTranslationsFor(language);
  i18nInstance.addResourceBundle(
    language,
    DEFAULT_NS,
    translations,
    true,
    true
  );
}

async function loadTranslationsFor(language: SupportedLanguage) {
  try {
    const module = await import(`assets/i18n/${language}.json`);
    return module.default;
  } catch (err) {
    throw new Error(
      `Cannot load translations for "${language}". The JSON file seems to be missing.`,
      { cause: err }
    );
  }
}

i18nInstance.init();
