import { AddressSuggestion } from '../types/models';

/**
 * @deprecated
 * TODO: delete together with the old address code when the time comes
 */
export const autoComplete = async (
  address: string,
  countryId: string,
  maxRecords: number = 10
): Promise<AddressSuggestion[] | null> => {
  const resp = await fetch(
    `${process.env.REACT_APP_ADRESS_LOOKUP_API}/addressautocomplete`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address: address,
        country: countryId,
        maxRecords: maxRecords
      })
    }
  );

  if (!resp.ok) {
    console.error('Address Lookup error', resp);
    return null;
  }

  let autoCompleteResp;
  try {
    autoCompleteResp = await resp.json();
  } catch (e) {
    console.error(
      'Could not deserialize response from Address Lookup service',
      e
    );
    return null;
  }

  if (!autoCompleteResp || !Array.isArray(autoCompleteResp)) {
    console.error(
      'Address Lookup response does not have expected format',
      autoCompleteResp
    );

    return null;
  }

  let suggestions: AddressSuggestion[] = autoCompleteResp
    .map(toAutoCompleteSuggestion)
    .filter((x: AddressSuggestion | null) => x != null)
    .map(x => x!);

  return suggestions;
};

const toAutoCompleteSuggestion = (result: any): AddressSuggestion | null => {
  if (!result) {
    return null;
  }

  return {
    suggestion: result.suggestion,
    suggestionShort: result.suggestionShort,
    addressLine1: result.streetAndNumber,
    postCode: result.postCode,
    locality: result.locality,
    administrativeArea: result.administrativeArea,
    subAdministrativeArea: result.subAdministrativeArea,
    country: result.countryCode
  };
};
