import { SupportedLanguage } from '@pmi.web/localization';
import bg from './bg.json';
import cs from './cs.json';
import deDE from './de-DE.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import fi from './fi.json';
import frFR from './fr-FR.json';
import hu from './hu.json';
import id from './id.json';
import is from './is.json';
import itIT from './it-IT.json';
import ja from './ja.json';
import ko from './ko.json';
import nb from './nb.json';
import nlNL from './nl-NL.json';
import pl from './pl.json';
import ptPT from './pt-PT.json';
import ruRU from './ru-RU.json';
import sv from './sv.json';
import th from './th.json';
import tr from './tr.json';
import uk from './uk.json';
import vi from './vi.json';
import zhHansCN from './zh-Hans-CN.json';
import zhHant from './zh-Hant.json';
import ro from './ro-RO.json';

export interface TranslationDictionary<T> {
  [key: string]: T;
}

export const translations: TranslationDictionary<
  TranslationDictionary<string>
> = {
  [SupportedLanguage.Bulgarian]: bg,
  [SupportedLanguage.ChineseSimplified]: zhHansCN,
  [SupportedLanguage.ChineseTraditional]: zhHant,
  [SupportedLanguage.Czech]: cs,
  [SupportedLanguage.Dutch]: nlNL,
  [SupportedLanguage.English]: enUS,
  [SupportedLanguage.Finnish]: fi,
  [SupportedLanguage.French]: frFR,
  [SupportedLanguage.German]: deDE,
  [SupportedLanguage.Hungarian]: hu,
  [SupportedLanguage.Icelandic]: is,
  [SupportedLanguage.Indonesian]: id,
  [SupportedLanguage.Italian]: itIT,
  [SupportedLanguage.Japanese]: ja,
  [SupportedLanguage.Korean]: ko,
  [SupportedLanguage.Norwegian]: nb,
  [SupportedLanguage.Polish]: pl,
  [SupportedLanguage.Portuguese]: ptPT,
  [SupportedLanguage.Romanian]: ro,
  [SupportedLanguage.Russian]: ruRU,
  [SupportedLanguage.Spanish]: esES,
  [SupportedLanguage.Swedish]: sv,
  [SupportedLanguage.Thai]: th,
  [SupportedLanguage.Turkish]: tr,
  [SupportedLanguage.Ukrainian]: uk,
  [SupportedLanguage.Vietnamese]: vi
};
