import { JsonSchema, Translator, UISchemaElement } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { ErrorObject } from 'ajv';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Ajv2019 from 'ajv/dist/2019';
import * as AjvFormats from 'ajv-formats';
import {
  vanillaCells as JSONFormsValinaCells,
  vanillaRenderers as JSONFormsValineRenderers
} from '../json-forms-renderers';
import './JSONForm.scss';
import { translations } from '../../translations';
import {
  toAddressAjvError,
  toDateOfBirthAjvError,
  toEmailAjvError,
  toTaxIdAjvError
} from './AjvErrorHelper';
import { labelForCountry } from '../../translations/LabelForCountry';

export interface IBusinessLogicError {
  message: string;
  field: string;
}

interface IJSONFormProps<T> {
  readonly env: 'development' | 'production';
  readonly defaultFormData?: T;
  readonly schema: JsonSchema;
  readonly uiSchema: UISchemaElement;
  readonly locale: string;
  readonly country: string;

  onDataChange(formData: T, errors: ErrorObject[]): void;
  backendJsonSchemaErrors?: ErrorObject[];
  businessLogicErrors?: IBusinessLogicError[];
}

const ajv = new Ajv2019({
  allowDate: true,
  allErrors: true,
  strictTuples: false,
  useDefaults: true,
  strict: false
});
AjvFormats.default(ajv);

function t(key: string, locale: string, country: string, param?: string) {
  let i18nKey = key;
  if (key.endsWith('label')) {
    i18nKey = labelForCountry(key, country);
  }

  const translation = translations[locale];
  let translated = i18nKey;

  if (i18nKey in translation) {
    translated = translation[i18nKey];
  }

  if (param) {
    return translated.replace('{{ numberOfCharacters }}', param);
  }

  return translated;
}

function translate(
  locale: string,
  country: string,
  id: string,
  defaultValue?: string
) {
  if (id.endsWith('description')) {
    return defaultValue; // do not translate descriptions - they're empty anyway
  }

  if (id.endsWith('error.custom')) {
    return defaultValue; // do not translate custom error messages - they're already translated
  }

  const translated = t(id, locale, country);

  if (translated === id) {
    // console.error(`Could not find translation for key ${id}`);
    return defaultValue;
  } else {
    return translated;
  }
}

export function JSONForm<T>(props: IJSONFormProps<T>) {
  const { onDataChange } = props;
  const [additionalErrors, setAdditionalErrors] = useState<
    ErrorObject[] | undefined
  >();

  useEffect(() => {
    const backendJsonSchemaErrors = props.backendJsonSchemaErrors
      ? [...props.backendJsonSchemaErrors]
      : [];
    const businessLogicErrors = props.businessLogicErrors
      ? [...props.businessLogicErrors]
      : [];

    const ajvErrors = businessLogicErrors.map(x => {
      // bogdan: In order to pass BusinessLogic errors from the parent all the way down to the JsonForms library
      // and have those errors nicely displayed on a specific field, we create/manipulate our own Ajv errors and
      // feed them to JsonForms library. This requires us to know what field names exist in the json schema
      // (so we violated separation of concerns). But for now there is no other alternative.
      if (x.field === 'address') {
        return toAddressAjvError(x.message);
      }

      if (x.field === 'dateOfBirth') {
        return toDateOfBirthAjvError(x.message);
      }

      if (x.field === 'email') {
        return toEmailAjvError(x.message);
      }

      if (x.field === 'taxId') {
        return toTaxIdAjvError(x.message);
      }

      throw new Error(`Unsupported error ${JSON.stringify(x)}`);
    });

    backendJsonSchemaErrors.push(...ajvErrors);

    setAdditionalErrors([...backendJsonSchemaErrors]);
  }, [props.businessLogicErrors, props.backendJsonSchemaErrors]);

  function translator(id: string, defaultMessage: string, values?: any): string;
  function translator(
    id: string,
    defaultMessage: undefined,
    values?: any
  ): string | undefined;
  function translator(
    id: string,
    defaultMessage?: string,
    values?: any
  ): string | undefined;
  function translator(id: string, defaultValue?: string): string | undefined {
    return translate(props.locale, props.country, id, defaultValue)
  }

  const translateError = useCallback(
    (
      error: ErrorObject,
      translate: Translator,
      uischema?: UISchemaElement
    ): string => {
      // bogdan: questions about the following line of code? go read the comments in AjvErrorHelper.ts
      if (error.data && error.message && (error.data as any).translated) {
        return error.message;
      }

      switch (error.keyword) {
        case 'required':
          return t('Field is required', props.locale, props.country);
        case 'format':
          return t('Format is not correct', props.locale, props.country);
        case 'minLength':
          // bogdan: make sure there's a space between {{ and `numberOfCharacters` and }}
          return t(
            'Field must have at least {{ numberOfCharacters }} characters',
            props.locale,
            props.country,
            error.params.limit
          );
        case 'maxLength':
          // bogdan: make sure there's a space between {{ and `numberOfCharacters` and }}
          return t(
            'Field cannot contain more than {{ numberOfCharacters }} characters',
            props.locale,
            props.country,
            error.params.limit
          );
        case 'enum':
          return t('Must select a value', props.locale, props.country);
        case 'pattern':
          return t('Format is not correct', props.locale, props.country);
      }

      console.error(
        `Did not find translation for:\n error: ${JSON.stringify(
          error
        )}\n translate: ${JSON.stringify(translate)}\n uiSchema: ${JSON.stringify(
          uischema
        )}`
      );

      return '';
    },
    [props.country, props.locale]
  );

  const onChange = useCallback(
    ({
      data,
      errors
    }: {
      readonly data: any;
      readonly errors?: ErrorObject[];
    }) => {
      onDataChange(data, errors ?? []);
    },
    [onDataChange]
  );

  const i18nCustomInstance = useMemo(() => {
    return {
      locale: props.locale,
      translateError: translateError,
      translate: translator
    };
  }, [props.locale, translateError, translator]);

  const jsonFormsConfig = useMemo(() => {
    return {
      country: props.country,
      env: props.env
    };
  }, [props.country, props.env]);

  return (
    <JsonForms
      schema={props.schema}
      uischema={props.uiSchema}
      data={props.defaultFormData}
      renderers={JSONFormsValineRenderers}
      cells={JSONFormsValinaCells}
      onChange={onChange}
      i18n={i18nCustomInstance}
      additionalErrors={additionalErrors}
      validationMode={'ValidateAndShow'}
      ajv={ajv}
      config={jsonFormsConfig}
    />
  );
}
