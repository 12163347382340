import PMIWeb from '@pmi.web/ui';
import ProspectRegistration from 'components/pages/registration/ProspectRegistration';
import {
  useContinueToken,
  useProspectId,
  useRedirectUrl
} from 'hooks/useCustomSearchParams';
import { useProspect } from 'hooks/useProspect';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

export function RegistrationResumePage() {
  const prospectId = useProspectId();
  const continueToken = useContinueToken();
  const redirectUrl = useRedirectUrl();
  const { isSuccess, error } = useProspect(prospectId, continueToken);

  useEffect(() => {
    if (!!error && redirectUrl) {
      window.location.assign(redirectUrl);
    }
  }, [error, redirectUrl]);

  if ((!!error && !redirectUrl) || continueToken === undefined) {
    return <Navigate to={'/404'} replace />;
  }

  if (isSuccess) {
    return <RegistrationResumePageContent />;
  }

  return (
    <div className="min-h-[150px] flex items-center justify-center">
      <PMIWeb.Components.LoadingSpinner />
    </div>
  );
}

function RegistrationResumePageContent() {
  const navigate = useNavigate();
  const prospectId = useProspectId();
  const redirectUrl = useRedirectUrl();
  const continueToken = useContinueToken();
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <ProspectRegistration
      prospectId={prospectId}
      redirectUrl={redirectUrl}
      continueToken={continueToken}
      onNeedsContinueToken={emailHint => {
        navigate(
          `/emailConfirmation?lang=${language}&hint=${emailHint}&prospectId=${prospectId}&redirectUrl=${redirectUrl}`
        );
      }}
    />
  );
}
