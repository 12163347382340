import { CellProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsCellProps } from '@jsonforms/react';
import type { VanillaRendererProps } from '../index';
import { withVanillaCellProps } from '../util/index';

export const DateOfBirthCell = (props: CellProps & VanillaRendererProps) => {
  const { data, className, id, enabled, uischema, path, handleChange } = props;

  let maxDate = undefined;

  if (uischema.options!.max! === '-18y') {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    maxDate = eighteenYearsAgo.toISOString().split('T')[0];
  }

  return (
    <input
      type="date"
      max={maxDate}
      value={data || ''}
      onChange={ev => handleChange(path, ev.target.value)}
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={uischema.options && uischema.options.focus}
    />
  );
};

export const dateOfBirthCellTester: RankedTester = rankWith(
  3,
  uiTypeIs('DateOfBirth')
);

export default withJsonFormsCellProps(withVanillaCellProps(DateOfBirthCell));
