import { useEffect, useState } from 'react';

interface IAsyncFetcherState<T> {
  readonly isLoading: boolean;
  readonly isSuccess: boolean;
  readonly error?: unknown;
  readonly data: T | undefined;
}

export function useAsyncFetcher<T>(fn: () => Promise<T>) {
  const [state, setState] = useState<IAsyncFetcherState<T>>({
    data: undefined,
    isLoading: false,
    isSuccess: false,
    error: undefined
  });

  useEffect(() => {
    setState({
      data: undefined,
      isLoading: true,
      isSuccess: false,
      error: undefined
    });

    fn()
      .then(d => {
        setState({
          data: d,
          isLoading: false,
          isSuccess: true,
          error: undefined
        });
      })
      .catch(err => {
        console.error(err);
        setState({
          data: undefined,
          isLoading: false,
          isSuccess: false,
          error: err
        });
      });
  }, [fn]);

  return state;
}
