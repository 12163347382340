import {
  localizedLanguageName,
  SupportedLanguage
} from '@pmi.web/localization';
import { setSelectedLanguage } from 'assets/i18n';
import { useLang } from 'hooks/useCustomSearchParams';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoLanguage } from 'react-icons/io5';

export function LanguageSelector() {
  const { activeLanguage, setActiveLanguage } = useLang();
  const {
    i18n: { language }
  } = useTranslation();

  const setLanguage = useCallback(
    (lang: string) => {
      setActiveLanguage(lang);
      setSelectedLanguage(lang);
    },
    [setActiveLanguage]
  );

  const onChangeLanguage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const lang = event.target.value;
      setLanguage(lang);
    },
    [setLanguage]
  );

  useEffect(() => {
    // Enforce the language in the URL if present on mount.
    // Afterwards, we control it internally and update the URL accordingly
    setLanguage(activeLanguage);
  }, []);

  return (
    <div className="flex flex-row items-center gap-xs">
      <IoLanguage size={16} />
      <select
        title="Language selector"
        className="text-sm bg-transparent"
        value={language}
        onChange={onChangeLanguage}
      >
        {Object.values(SupportedLanguage).map(supportedLanguage => (
          <option key={supportedLanguage} value={supportedLanguage}>
            {localizedLanguageName(supportedLanguage)}
          </option>
        ))}
      </select>
    </div>
  );
}
